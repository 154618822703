.auth_title {
    font-family: "Playball", cursive;
    font-weight: normal;
    text-align: center;
  }
  .auth_error {
    color: #ff00ff;
    text-align: center;
    margin: 10px;
  }
  .auth_signUp {
    display: flex;
    flex-direction: column;
  }
  .auth_headerImage {
    object-fit: contain;
  }
  .auth_text {
    color: rgb(6, 153, 211);
    text-align: center;
    cursor: pointer;
  }
  .auth_progress {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  